import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import WorkListingLayout from '../../layouts/work/WorkListingLayout'
import SEO from '../../components/seo'
import './work-archive.scss'

export default class WorkArchive extends Component {
    render() {
        const pageData = this.props.data.wordpressPage
        const works = this.props.data.allWordpressWpWorks.edges
        return (
            <WorkListingLayout theme="lightGradient">
                <SEO
                    yoast={pageData.yoast}
                    title={pageData.title}
                    description={pageData.content}
                    dateModified={pageData.modified}
                    datePublished={pageData.date}
                />
                <section className="WorkArchive">
                    <ul className="list-unstyled">
                        {works.map(({ node: work }, index) => {
                            const {
                                id,
                                slug,
                                title,
                                worktypes: categories,
                                workstatus,
                                date,
                            } = work
                            let isWorkItemLinkable = true
                            if (workstatus) {
                                workstatus.forEach(status => {
                                    isWorkItemLinkable =
                                        status.name === 'Archived'
                                            ? false
                                            : true
                                })
                            }
                            let Container = 'div'
                            let containerProps = {
                                className: 'd-flex py-3',
                            }
                            if (isWorkItemLinkable) {
                                Container = Link
                                containerProps['to'] = `/projects/${slug}`
                            }
                            return (
                                <li
                                    key={id}
                                    className="work-list-item border-bottom border-black"
                                >
                                    <Container {...containerProps}>
                                        <div style={{ flex: 0.5 }}>{date}</div>
                                        <div style={{ flex: 1 }}>
                                            <span
                                                className="title"
                                                dangerouslySetInnerHTML={{
                                                    __html: title,
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{ flex: 1 }}
                                            className="d-none d-md-block"
                                        >
                                            {categories
                                                .map(category => category.name)
                                                .join(' / ')}
                                        </div>
                                    </Container>
                                </li>
                            )
                        })}
                    </ul>
                </section>
            </WorkListingLayout>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            ...YoastPage
        }
        allWordpressWpWorks(sort: { order: DESC, fields: [date] }) {
            edges {
                node {
                    id
                    title
                    date(formatString: "YYYY")
                    wordpress_id
                    content
                    slug
                    workstatus {
                        name
                    }
                    worktypes {
                        name
                    }
                }
            }
        }
    }
`
